<template>
  <b-overlay
    id="blank-canvas"
    :show="stateOverlayForCanvas || stateOverlayForKO"
  >
    <CanvasHeader
      :pageType="'blankCanvas'"
      :clearTheCanvas="clearTheCanvas"
      :commitKO="commitKO"
      :addName="addName"
      :saveBlankCanvas="saveNewCanvas"
      :isParkingBlank="!isNewBlankCanvas"
      :inputName="inputName"
    />

    <!-- <BModal
      id="modal-center"
      title="Save"
      @cancel="cancelSaveCanvas"
      @ok="saveNewCanvas"
      v-model="showModalSaveCanvas"
      centered
    >
      <p class="my-4">Are you sure to save the Canvas?</p>
    </BModal> -->
    <img
      class="blank-canvas__img-top"
      src="../assets/for-items-top.png"
      @click="toggleNewThought"
    />
    <div id="darken-block" v-if="IsOpenNewThought" @click="toggleNewThought" />
    <NewLooseThought
      class="blank-canvas__new-thought-dropdown"
      v-if="IsOpenNewThought"
      :toggleNewThought="toggleNewThought"
    />

    <div id="body-element">
      <div id="blank-canvas__KO-list">
        <Vue3DraggableResizable
          :initW="0"
          :initH="0"
          :draggable="true"
          :resizable="false"
          :active="true"
          v-on:dragging="movedKO"
          :x="convertToPixeles(getPositionForKO(item).x, 0).x"
          :y="convertToPixeles(0, getPositionForKO(item).y).y"
          @drag-end="dragStop(item.knowledgeObjectId)"
          v-for="item in KOItems"
          :key="item.knowledgeObjectId"
          class="KO-list-item"
          :parent="true"
        >
          <KOItem
            :body="item.body"
            :deleteKO="deleteKo"
            :updateKo="updateKO"
            :metatags="item.metatags"
            :type="'blank-canvas'"
            :knowledge-object-id="item.knowledgeObjectId"
          />
        </Vue3DraggableResizable>
      </div>
      <TextEntryBox
        v-if="isOpenAddingBlock"
        :type="type"
        :isCommitKO="isCommitKO"
        :KOForUpdate="KOForUpdate"
        :addingBlockPosition="addingBlockPosition"
        ref="textEntryBoxRef"
      />
    </div>
    <div class="link">
      <ul class="link-list">
        <li class="link-list__item">
          <input
            v-if="!isEnteringName"
            class="parking-input"
            type="button"
            :value="
              isNewBlankCanvas
                ? 'Enter Name for Blank Canvas'
                : 'Create New Canvas'
            "
            @click="addName"
          />
          <input
            v-else
            type="text"
            class="parking-input"
            v-model="inputName"
            :ref="'input_canvas_name'"
          />
        </li>
        <li
          class="link-list__item"
          v-for="item in blankCanvases"
          :key="item.userCanvasId"
          v-on:click="showSelectBlankCanvas(item)"
        >
          <p class="link-list__item-name">{{ item.canvasName }}</p>
        </li>
      </ul>
    </div>
  </b-overlay>
</template>

<script>
  import { mapGetters } from "vuex";
  import TextEntryBox from "./TextEntryBox.vue";
  import {
    convertToPercentage,
    convertToPixeles,
    filterKOPosition,
    getItemFromLS,
    getPositionForKO,
    setItemToLS,
    updatePositionKo,
  } from "../functions";
  import CanvasHeader from "./PreviousBlankHeader.vue";
  import NewLooseThought from "./NewLooseThoughts";
  import KOItem from "./KOItem";
  import Vue3DraggableResizable from "vue3-draggable-resizable";
  export default {
    name: "BlankCanvas",
    data() {
      return {
        isPossibilityUpdateKo: false,
        isOpenAddingBlock: false,
        isCommitKO: false,
        KOForUpdate: {},
        isEnteringName: false,
        inputName: "",
        IsOpenNewThought: false,
        isOpenUpdateKo: false,
        addingBlockPosition: {
          top: 240,
          left: 450,
        },
        top: 0,
        left: 0,
        isNewBlankCanvas: true,
        selectCanvasName: null,
        idSelectBlankCanvas: "",
        showModalSaveCanvas: false,
        type: "blankCanvas",
        movedItem: false
      };
    },
    computed: {
      ...mapGetters({
        newCanvas: "canvasStore/newCanvas",
        blankCanvases: "canvasStore/blankCanvases",
        KOItems: "KOStore/KOItems",
        stateOverlayForCanvas: "canvasStore/stateOverlayForCanvas",
        stateOverlayForKO: "KOStore/stateOverlayForKO",
        parkCanvas: "canvasStore/previousCanvas",
        currentThoughtId: "thoughtsStore/currentThoughtId",
      }),
    },
    components: {
      TextEntryBox,
      CanvasHeader,
      NewLooseThought,
      KOItem,
      Vue3DraggableResizable,
    },
    watch: {
      blankCanvases() {
        this.isOpenAddingBlock = true;
      },
      KOItems() {
        this.isOpenAddingBlock = false;
      },
      parkCanvas() {
        let blank = this.parkCanvas;
        if (blank && blank.userCanvasId === this.idSelectBlankCanvas) {
          this.$store.commit("KOStore/setKOItems", blank.KOs);
        }
      },
    },
    mounted() {
      if (this.newCanvas && (this.newCanvas.body || this.newCanvas.metatags))
        this.toggleAddingBlock();
      const canvasPlace = document.getElementById("blank-canvas");
      canvasPlace.addEventListener("click", this.onClick);
      const previousUrl = sessionStorage.getItem("previousUrl");

      if (previousUrl !== "/loose-thoughts") {
        this.$store.commit("thoughtsStore/setCurrentThoughtId", "");
      }
    },
    beforeUnmount() {
      if (!this.isNewBlankCanvas) this.updateCanvas(this.selectCanvasName);
    },
    methods: {
      getItemFromLS,
      setItemToLS,
      convertToPixeles,
      convertToPercentage,
      getPositionForKO,
      filterKOPosition,
      updatePositionKo,
      showSaveModal() {
        this.showModalSaveCanvas = true;
      },
      cancelSaveCanvas() {
        this.showModalSaveCanvas = false;
        this.inputName = "";
        this.isEnteringName = false;
        this.selectCanvasName = "";
      },
      commitKO() {
        let KOs = getItemFromLS("KOItems");
        KOs = KOs ? KOs : [];

        const updatedCanvas = {
          canvasName: this.selectCanvasName,
          koUpdates: KOs,
          koLocations: JSON.stringify(getItemFromLS("koLocations")),
        };
        updatedCanvas.koUpdates = updatedCanvas.koUpdates.map((el) => {
          el = {
            id: el.knowledgeObjectId,
            updateType: "add",
          };
          return el;
        });
        this.setItemToLS("updatedCanvas", updatedCanvas);
        this.$store
          .dispatch("canvasStore/updateCanvas", this.idSelectBlankCanvas)
          .then(() => {
            this.$store.dispatch(
              "canvasStore/getCanvas",
              this.idSelectBlankCanvas
            );
          });
      },

      onClick(e) {
        let correctX = 20;
        let correctY = 60;
        if (e.target.id || e.target.className.indexOf) {
          if (this.isClickOkayForOpenTextEntryBox(e)) {
            if (this.isEnteringName && this.inputName === "")
              this.isEnteringName = false;
            this.addingBlockPosition.top = e.clientY - correctY;
            this.addingBlockPosition.left = e.clientX - correctX;
            this.toggleAddingBlock();
            this.IsOpenNewThought = false;
          }
          if (e.target.className.indexOf("main-adding-block__save") !== -1)
            this.isOpenAddingBlock = false;
        }
        this.isPossibilityUpdateKo = true;
      },

      toggleAddingBlock() {
        this.isOpenAddingBlock = true;
        if (this.isOpenUpdateKo) {
          this.isOpenUpdateKo = false;
          this.isOpenAddingBlock = false;
        }
        // this.isOpenAddingBlock = !this.isOpenAddingBlock;
      },

      addName() {
        this.type = "blankCanvas";
        if (this.isNewBlankCanvas) {
          if (
            this.$refs.textEntryBoxRef &&
            this.$refs.textEntryBoxRef.textAreaValue &&
            this.$refs.textEntryBoxRef.metatags
          ) {
            this.$refs.textEntryBoxRef.commitKO(
              this.$refs.textEntryBoxRef.textAreaValue,
              this.$refs.textEntryBoxRef.metatags
            );
            this.isOpenAddingBlock = false;
          }

          this.isEnteringName = true;
          if (this.inputName.length) this.saveNewCanvas();
          // else this.isEnteringName = true;
          // this.$nextTick(() => {
          //   if (this.$refs["input_canvas_name"]) {
          //     this.$refs["input_canvas_name"].focus();
          //   }
          // });
        } else {
          this.updateCanvas(this.selectCanvasName);
          this.clearTheCanvas();
          this.selectCanvasName = null;
          this.isNewBlankCanvas = true;
        }

        this.setClassForSelectItem();
      },

      async saveNewCanvas() {
        this.showModalSaveCanvas = false;
        this.idSelectBlankCanvas = "";
        this.selectCanvasName = null;
        this.isEnteringName = false;

        if (this.currentThoughtId !== "") {
          this.$store.dispatch(
            "thoughtsStore/deleteLooseThought",
            this.currentThoughtId
          );

          this.$store.commit("thoughtsStore/setCurrentThoughtId", "");
        }

        let payload = {
          canvasName: this.inputName
            ? this.inputName
            : `New Canvas ${this.blankCanvases.length + 1}`,
          KOs: [],
        };
        await this.$store
          .dispatch("canvasStore/saveNewCanvas", payload)
          .then(() => {
            this.inputName = "";
            const userCanvasId = getItemFromLS("userCanvasId");
            localStorage.removeItem("userCanvasId");
            this.sendCanvasAfterSavingForUpdate(payload, userCanvasId);
          });
      },

      sendCanvasAfterSavingForUpdate(payload, userCanvasId) {
        const blankCanvases = this.$store.getters["canvasStore/blankCanvases"];
        blankCanvases.splice(0, 0, {
          userCanvasId: userCanvasId,
          canvasName: payload.canvasName,
        });
        this.$store.commit("canvasStore/setBlankCanvases", blankCanvases);
        const KOItems = this.$store.getters["KOStore/KOItems"];
        if (KOItems.length) {
          const KOsForUpdate = [];
          KOItems.forEach((el) => {
            KOsForUpdate.push({ id: el.knowledgeObjectId, updateType: "add" });
          });

          filterKOPosition();
          let koLocations = getItemFromLS("koLocations");
          const updatedCanvas = {
            canvasName: payload.canvasName,
            koUpdates: KOsForUpdate,
            koLocations: JSON.stringify(koLocations),
          };
          this.setItemToLS("updatedCanvas", updatedCanvas);
          this.$store.dispatch("canvasStore/updateCanvas", userCanvasId);
          this.$store.commit("KOStore/setKOItems", []);
        }
      },

      async updateCanvas(canvasName) {
        const updatedCanvas = {
          canvasName,
          koUpdates: [],
          koLocations: JSON.stringify(getItemFromLS("koLocations")),
        };
        this.setItemToLS("updatedCanvas", updatedCanvas);
        await this.$store.dispatch(
          `canvasStore/updateCanvas`,
          this.idSelectBlankCanvas
        );
      },

      async clearTheCanvas() {
        if (this.isNewBlankCanvas)
          await this.$store.dispatch("KOStore/deleteKOItems", this.KOItems);

        localStorage.removeItem("newCanvas");
        localStorage.removeItem("KOItems");
        localStorage.removeItem("newKO");
        this.$store.commit("KOStore/setKOItems", []);
        this.isOpenAddingBlock = true;
        this.selectCanvasName = null;
      },

      updateKO(itemKO) {
        let itemPosition = getItemFromLS("koLocations").find(
          (el) => el.id === itemKO.knowledgeObjectId
        );
        if (itemPosition.y && itemPosition.x) {
          this.top = itemPosition.y;
          this.left = itemPosition.x;
          itemKO.left = itemPosition.x;
          itemKO.top = itemPosition.y;
          this.addingBlockPosition = {
            left: convertToPixeles(itemPosition.x + 0.8, 0).x,
            top: convertToPixeles(0, itemPosition.y + 5).y,
          };
        }

        this.KOForUpdate = itemKO;
        this.isOpenAddingBlock = !this.isOpenAddingBlock;

        this.isOpenUpdateKo = true;

        setTimeout(() => {
          this.KOForUpdate = {};
        }, 1000);
      },

      movedKO(newRect) {
        this.top = newRect.y;
        this.left = newRect.x;
        this.movedItem = true;
      },

      dragStop(knowledgeObjectId) {
        let position = this.convertToPercentage(this.left, this.top);
        let updateKO = this.KOItems.filter(
          (elem) => String(elem.knowledgeObjectId) === String(knowledgeObjectId)
        )[0];
        let newKo = {
          y: position.y,
          x: position.x,
          body: updateKO.body,
          metatags: updateKO.metatags,
        };

        if (this.isPossibilityUpdateKo && this.movedItem) {
          this.setItemToLS("updatedKO", newKo);
          this.updatePositionKo(knowledgeObjectId, newKo);
        }
        this.isPossibilityUpdateKo = false;
        this.movedItem = false;
      },

      async deleteKo(id) {
        if (!this.isNewBlankCanvas) {
          const updatedCanvas = {
            canvasName: this.selectCanvasName,
            koUpdates: [id, "remove"],
            koLocations: JSON.stringify(getItemFromLS("koLocations")),
          };
          this.setItemToLS("updatedCanvas", updatedCanvas);
          await this.$store.dispatch(
            `canvasStore/updateCanvas`,
            this.idSelectBlankCanvas
          );
        }
        await this.$store.dispatch(`KOStore/deleteKOItem`, id);
      },

      toggleNewThought() {
        this.IsOpenNewThought = !this.IsOpenNewThought;
        if (this.isOpenAddingBlock) this.isOpenAddingBlock = false;
      },

      async showSelectBlankCanvas(canvas) {
        if (this.isNewBlankCanvas && this.KOItems.length)
          await this.saveNewCanvas();

        if (!this.isNewBlankCanvas)
          await this.updateCanvas(this.selectCanvasName);

        this.idSelectBlankCanvas = canvas.userCanvasId;
        this.selectCanvasName = canvas.canvasName;

        this.$store
          .dispatch(`canvasStore/getCanvas`, this.idSelectBlankCanvas)
          .then(() => {
            this.isNewBlankCanvas = false;
            this.isOpenAddingBlock = false;
            this.isEnteringName = false;
            this.setClassForSelectItem();
          });
      },

      setClassForSelectItem() {
        let listItem = document.getElementsByClassName("link-list__item-name");
        for (let i = 0; i < listItem.length; i++) {
          if (listItem[i].textContent === this.selectCanvasName)
            listItem[i].classList.toggle("is-select-canvas", true);
          else listItem[i].classList.toggle("is-select-canvas", false);
        }
      },

      isClickOkayForOpenTextEntryBox(e) {
        return (
          e.target.id === "blank-canvas" ||
          e.target.id === "blank-canvas__KO-list" ||
          e.target.className.indexOf("loose-thoughts ") !== -1
        );
      },
    },

    unmounted() {
      this.$store.commit("canvasStore/setBlankCanvases", []);
      this.setItemToLS("koLocations", []);
      this.clearTheCanvas();
    },
  };
</script>

<style scoped lang="scss">
  #blank-canvas {
    min-height: 100vh;
    background: rgb(255, 255, 255);
    position: relative;

    &__KO-list {
      margin-top: 20px;
      margin-left: 1vw;
      position: relative;
      min-height: 85vh;
      width: 96vw;

      .KO-list-item {
        position: absolute;
        width: 220px;

        &::before {
          outline: none;
        }
      }
    }

    .link {
      position: absolute;
      bottom: 0.5px;
      right: 0;
      width: 100vw;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      &-list {
        width: fit-content;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        overflow-x: auto;

        &__item {
          width: 350px;
          height: 25px;
          background-image: url("../assets/for-items-bottom.png");
          background-repeat: no-repeat;
          background-size: cover;

          &-name {
            color: rgb(29, 29, 29);
            width: 350px;
            height: 100%;
            border: none;
            font-size: 14px;
            word-wrap: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
            padding: 0 40px;

            -ms-text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            -ms-line-clamp: 1;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            box-orient: vertical;
            text-align: center;
          }

          .parking {
            &-btn {
              background-image: url("../assets/for-items-bottom.png");
              background-color: transparent;
              width: 350px;
              height: 100%;
              background-repeat: no-repeat;
              border: none;
              background-size: cover;
              font-size: 14px;

              &:hover {
                cursor: pointer;
              }
            }

            &-input {
              background-image: url("../assets/for-items-bottom.png");
              width: 350px;
              height: 100%;
              background-repeat: no-repeat;
              border: none;
              outline: none;
              background-size: cover;
              font-size: 14px;
              padding: 0 40px;
              text-align: center;
            }
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .blank-canvas__img-top {
      display: block;
      width: 280px;
      z-index: 26;
      position: absolute;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .is-select-canvas {
    text-shadow: 0 0 2px rgba(100, 100, 100, 0.5),
      0 0 1em rgba(200, 200, 200, 0.5), 0 0 0.2em rgba(50, 50, 50, 0.5);
  }

  .blank-canvas__new-thought-dropdown {
    position: absolute;
    animation-duration: 300ms;
    animation-name: slidein1;
    animation-iteration-count: 1;
    z-index: 30;
    top: 20px;
  }

  #body-element {
    position: absolute;
    top: 63px;
    left: 1.5vw;
    height: calc(100vh - 63px);
    overflow-y: hidden;
    z-index: 0;
  }

  @keyframes slidein1 {
    from {
      opacity: 0;
      top: -330px;
    }

    to {
      opacity: 1;
      top: 20px;
    }
  }

  @media (min-width: 500px) {
    @keyframes slidein {
      from {
        opacity: 0;
        top: -330px;
      }

      to {
        opacity: 1;
        top: 60px;
      }
    }

    .blank-canvas {
      &__img-top {
        width: 320px;
        top: 43px;
      }
    }
    .link-list__item {
      width: 320px;
    }
  }

  @media (min-width: 1500px) {
    @keyframes slidein {
      from {
        opacity: 0;
        top: -330px;
      }

      to {
        opacity: 1;
        top: 80px;
      }
    }

    .blank-canvas {
      &__img-top {
        width: 320px;
        top: 59px;
      }
    }

    .link-list__item {
      width: 320px;
    }

    .parking-btn {
      font-size: 16px;
    }
  }

  @media (min-width: 1800px) {
    @keyframes slidein {
      from {
        opacity: 0;
        top: -330px;
      }

      to {
        opacity: 1;
        top: 80px;
      }
    }

    .blank-canvas {
      &__img-top {
        width: 360px;
        top: 59px;
      }
    }

    .link-list__item {
      width: 350px;
    }

    .parking-btn {
      font-size: 16px;
    }
  }
</style>
