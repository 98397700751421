import { getItemFromLS, setItemToLS } from "../functions";
import axios from "../axios";

export const state = () => ({
  newCanvas: {},
  canvases: [],
  blankCanvases: [],
  previousCanvas: {},
  stateOverlayForCanvas: false,
});
export const getters = {
  newCanvas: (s) => s.newCanvas,
  canvases: (s) => s.canvases,
  previousCanvas: (s) => s.previousCanvas,
  blankCanvases: (s) => s.blankCanvases,
  stateOverlayForCanvas: (s) => s.stateOverlayForCanvas,
};
export const mutations = {
  setNewCanvas(state, newCanvas) {
    state.newCanvas = newCanvas;
  },
  setCanvases(state, canvases) {
    state.canvases = canvases;
  },
  setPreviousCanvas(state, previousCanvas) {
    state.previousCanvas = previousCanvas;
  },
  setBlankCanvases(state, blankCanvases) {
    state.blankCanvases = blankCanvases;
  },
  setStateOverlayForCanvas(state, payload) {
    state.stateOverlayForCanvas = payload;
  },
};
export const actions = {
  async getCanvases({ commit }) {
    await axios
      .get("main/user/canvases")
      .then((res) => {
        commit("setCanvases", res.data.body);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async getCanvas({ commit }, payload) {
    commit("setPreviousCanvas", {});
    commit("setStateOverlayForCanvas", true);
    await axios
      .get(`main/user/canvas/${payload}`)
      .then((res) => {
        commit("setPreviousCanvas", res.data.body);
        commit("setStateOverlayForCanvas", false);

        if (typeof res.data.body.koLocations === "string") {
          const koLocations = res.data.body.koLocations;
          setItemToLS("koLocations", JSON.parse(koLocations));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async saveNewCanvas({ commit }, payload) {
    const newCanvas = {
      canvasName: payload.canvasName,
      KOs: payload.KOs,
      koLocations: "[]",
    };
    commit("setStateOverlayForCanvas", true);
    await axios
      .post("main/user/canvas/", newCanvas)
      .then((res) => {
        commit("setStateOverlayForCanvas", false);
        setItemToLS("userCanvasId", res.data.body.userCanvasId);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async updateCanvas({commit}, payload) {
    const updatedCanvas = getItemFromLS("updatedCanvas");
    try {
      await axios
        .patch(`main/user/canvas/${payload}`, updatedCanvas)
        .then(() => {
          setItemToLS("updatedCanvas", {});
        });
    } catch (e) {
      console.log(e);
    }
  },
  async deleteCanvas({ commit }, payload) {
    try {
      await axios.delete(`main/user/canvas/${payload}`);
      commit("setPreviousCanvas", {});
    } catch (e) {
      console.log(e);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
